<template>

    <div class="col-md-8 p-md-5" >
        <b-carousel
        id="carousel-1"
        v-model="slide"
        controls
        
        background="#ababab"
        class="pl-md-5"
        
        style="text-shadow: 1px 1px 2px #333; background:transparent; "
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"      
      >
        <!-- <b-carousel-slide :img-src="require('@/assets/slide1.jpg')" />
        <b-carousel-slide :img-src="require('@/assets/slide2.jpg')"/>
        <b-carousel-slide :img-src="require('@/assets/slide3.jpg')"/>
        <b-carousel-slide :img-src="require('@/assets/slide4.jpg')"/>
        <b-carousel-slide :img-src="require('@/assets/slide5.jpg')"/> -->


        <b-carousel-slide v-for="pr in datos" :key="pr.id" class="p-0" style=" background: transparent !important;">
          <template class="p-0" #img style=" background: transparent !important;">
            
            <div class="row p-0 card_full" style=" background: transparent !important;">
              <card-img :class="'col-'+ pr.columnas" 
              :key="pr.id"
              :product="pr" />
            </div>

            <div class="row px-md-3 card_movil"  >
              <card-img :class="'px-0 col-12'" 
              :key="pr.id"
              :product="pr" />
            </div>
            
          </template>
        </b-carousel-slide>

<!-- 
        <b-carousel-slide class="p-0" style=" background: transparent !important;">
          <template class="p-0" #img style=" background: transparent !important;">
            <div class="row p-0 card_full" style=" background: transparent !important;">
              <card-img :class="'col-'+ product.columnas" v-for="product in datos" 
              :key="product.id"
              :product="product" />

            </div>
            <div class="row px-md-3 card_movil"  >
              <card-img :class="'px-0 col-12'" v-for="product in datos" 
              :key="product.id"
              :product="product" />
            </div>
          </template>
        </b-carousel-slide> -->




        <!-- <b-carousel-slide class="p-0" style=" background: transparent !important;">
          <template class="p-0" #img style=" background: transparent !important;">
            <div class="row p-0 card_full" style=" background: transparent !important;">
              <card-img :class="'col-'+ product.columnas" v-for="product in datos" 
              :key="product.id"
              :product="product" />

            </div>
            <div class="row px-md-3 card_movil"  >
              <card-img :class="'px-0 col-12'" v-for="product in datos" 
              :key="product.id"
              :product="product" />
              
            </div>
            
          </template>
          
        </b-carousel-slide> -->
          
        </b-carousel>
          
          
          
          <!-- <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
            >                       
                <b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image">
                    <div class="row px-3 " style="whidth:1024px !imporatant; height:480px !important;" >
                        <card-img :class="'col-'+ product.columnas" v-for="product in card_img2" 
                        :key="product.id"
                        :product="product" />

                    </div>
                </b-carousel-slide>
                
            </b-carousel> -->
    </div>

</template>

<script>
import CardImg from "../components/Card-img.vue";
export default {
  components: { CardImg },
  props: [ "theme" , "datos"],
  data: () => ({
    className: "product",
    user: null
  }),
  mounted() {
    this.user = JSON.parse(localStorage.getproduct("user")) || false; //this.$store.state.user!=null ? true : false;
    if (this.theme) {
      this.className = this.theme;
    }
  },
  methods:{
    goto(url){
      if(url.includes('http')){
        console.log('web', url)
        window.open(url)
      }
      else{
        window.location.href =url
        console.log('in', url)
      }      
      // console.log('goto ruta: ', rutaWeb)
      // this.$router.push(rutaWeb)
    }
  }
};
</script>

<style lang="scss" >
$primary: rgba(255, 0, 119, 1) !important;

.shadow_card {
  background: transparent;
  box-shadow: 0 .185rem .30rem rgba(0, 0, 0, 0.452)!important;
 
  
}

h4 {
  color: $primary;
}

.product-alt {
  background: transparent;
  border: 1px solid $primary;
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
  color: #fff;
}
.productimg{
  height:10rem; width:80%; display:block; margin:auto
}

@media screen and (max-width: 600px) {
 .productimg{
  height:10rem; width:50%; display:block; margin:auto
}
}
</style>