<template>
<div>
  <div class="container-fluid bg-gray pb-4">
      <div class="row pb-4 pt-4">
            <div class="col-md-6 offset-md-1 px-5 pl-5 mt-5">
                <div class="ml-3 mt-5 pt-5">
                    <div class=" mt-5 pt-2">
                        <h1 class="text-left">¡Enviada!</h1>
                    </div>
                    <p class="mt-3">Tu contraseña ha sido enviada a tu correo.</p>
                    <button @click="$router.push('login_new/recuperacion')" class="btn btn-primary mt-2">Iniciar sesión</button>
                </div>
            </div>
          
          <div class="col-md-5 py-5 mt-5" >
           <img class="img-fluid" src="../assets/NAO/2-home/register.png" alt="">
          </div>
      </div>
  </div>
</div>
</template>

<script>
import ProductVue from "../components/Product.vue";
import CardImg from "../components/Card-img.vue";
import ProductDetailVue from "../components/ProductDetail.vue";


export default {
  components: { ProductVue, ProductDetailVue, CardImg },
  data() {
    return {
      slide: 0,
      sliding: null,
     categories: [],
      productData: {},
      storeId: 0,
      productSearch: null,

      products: [],

      offerProducts: [],
      card_img:[
        {id: 1, 
        titulo: "Cruceros", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 12, 
        img: '../img/Banner2.png',
        posicionpanel: 'arriba', 
        columnaspanel:6,
        width: '480',
        height: '370',
        alturapanel: 170,
         
        panelhorizontal: 0,
        izquierda: 6,
        },
        {id:2, 
        titulo: "Circuitos", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 7 , 
        img: '../img/Banner.png',
        posicionpanel: 370, 
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 0,
        alturapanel: 170,
        
        },
        
        {id: 3, 
        titulo: "Hoteles", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 5, 
        img: '../img/Banner_1.png',
        posicionpanel: 'arriba', 
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 115,
        panelhorizontal: 1,
        col_text: 7,
        col_button: 4,
        
        },
        {id: 3, 
        titulo: "Luxury Collection", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 5, 
        img: '../img/Banner_2.png',
        posicionpanel: 'arriba', 
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 115,
        posicionpanel: 370, 
        col_text: 7,
        col_button: 4,
        panelhorizontal: 1,
        },
        {id:4, 
        titulo: "Promociones y recursos para agentes de viaje ", 
        descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.", 
        textoboton: "Ver mas", 
        columnas: 7 , 
        img: '../img/Banner_3.png',
        posicionpanel: 370, 
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 2,
        alturapanel: 200,
        
        },
        
        
      ],
      card_img2:[
        {id:1, 
        titulo: " Promociones y recursos para agentes de viaje  ", 
        descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.", 
        textoboton: "Acceder ahora", 
        columnas: 12 , 
        img: '../img/b3.png',
        posicionpanel: 768, 
        columnaspanel:12 ,
        
        width: '930',
        height: '778',
        izquierda: 0,
        alturapanel: 230,
        col_text: 8,
        col_button: 4,
        
        },
        
        
        
        
        
      ]
      
    };
  },
  mounted() {
    
  },
  methods: {
    goSearch() {
      localStorage.setItem("search", this.productSearch);
      this.$router.push("store/0");
    },
    gotoStore(category) {
      this.$router.push("store/" + category);
    },
    async getProducts() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.products = await this.$store.dispatch(
          "getAll",
          "newProductsByStore/" + this.storeId
        );
      } catch (error) {
        alert(error);
      }
    },
    async productsOffer() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.offerProducts = await this.$store.dispatch(
          "getAll",
          "productsOffer/" + this.storeId
        );
        if(this.offerProducts){
          this.offerProducts = this.offerProducts.filter(x => x.variations.length>0);
        }
      } catch (error) {
        alert(error);
      }
    },
    showProductDetail(item) {
      item["quantity"] = 1;
      this.productData = item;
      this.$bvModal.show("modal-productDetail");
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    showModal() {
      this.$bvModal.show("modal-chooseStore");
    },
    closeModal() {
      this.$bvModal.hide("modal-chooseStore");
      this.$bvModal.hide("modal-productDetail");
    },
  },
};
</script>
<style>

.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  
  word-wrap: break-word !important; 
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;
  
  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{
  
  height:auto !important;
  width:264px !important;
  
}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

 
</style>