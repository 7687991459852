<template>
<div >
  
  <div class="container-fluid bg-primary p-md-5 m-0 py-5">
    <div class="row px-4">
      <div class="col-sm-12 col-md-4 mt-5">
        <img src="../assets/NAO/2-home/naologo2.png" alt="" width="250px">
        <p @click="$router.push('/login_new/footer')" class="mb-1 text-light pointer mt-4">Login Nao</p>
        <p class="text-light mt-4">Consulta nuestro 
          <a class="text-light pointer" href="/avisodeprivacidad" target="_blank"> Aviso de Privacidad. </a>
        </p>
      </div>
      <div class="col-md-4 ">
        <h3 class="text-light py-3 mt-4">Navegación</h3>
        <div class="row">
          <div class="col-6 text-light">
            <p><a class="text-light pointer" @click="$router.push('/')" href=""> Inicio</a></p>
            <p><a class="text-light pointer" @click="$router.push('/hotel')" > Hoteles</a></p>
            <p><a class="text-light pointer" @click="$router.push('/cruceros')"> Cruceros</a></p>
            <p><a class="text-light pointer" @click="$router.push('/circuitos')"> Circuitos</a></p>
            <p><a class="text-light pointer" @click="$router.push('/luxury')"> Luxury Colection</a></p>
          </div>
          <div class="col-6 text-light">
            <p><a class="text-light pointer" @click="$router.push('/parques')"> Parques</a></p>
            <p><a class="text-light pointer" @click="$router.push('/otros')"> Otros Servicios</a></p>
            <p><a class="text-light pointer" >Promociones</a></p>
            <p><a class="text-light pointer" >Recursos</a></p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 offset-md-1 col-md-3 ">
        <h3 class="text-light py-3 mt-4">Contacto</h3>
        <div>
          <a target="_blank" href="https://www.facebook.com/search/top?q=nao%20travel%20collection"> <img class="pr-1 hover-img" src="/img/face.png" alt="" width="40px"></a>
          <a target="_blank" href="https://www.linkedin.com/company/80428644/admin/"><img class="px-1 hover-img" src="/img/lin.png" alt="" width="45px"></a>
          <a target="_blank" href="https://www.instagram.com/nao_travel_collection/"><img class="px-1 hover-img" src="/img/instagram.png" alt="" width="45px"></a>
          <a target="_blank" href="https://www.youtube.com/channel/UCk9rpj4kvRHGH8tVKKtYCCw"><img class="px-1 hover-img" src="/img/youtube.png" alt="" width="45px"></a>
          <a target="_blank" href=""><img class="px-1 hover-img" src="/img/twitter.png" alt="" width="45px"></a>
       </div>
       <div class="text-white px-1 py-3">
          <!-- <p data-aos="fade-right" data-aos-duration="950"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (1).png" alt=""><a href="phone:(55)66513626">(55) 66 51 36 26</a></p>
          <p data-aos="fade-right" data-aos-duration="1000"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (2).png" alt=""><a href="https://api.whatsapp.com/send?phone=+525513608649">+ 52 55 13 60 86 49</a></p>
          <p data-aos="fade-right" data-aos-duration="1050"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (3).png" alt=""><a class="text-decoration:none" href="mailto:hola@naotravelco.com">hola@naotravelco.com</a></p>
          <p data-aos="fade-right" data-aos-duration="1100"><img class="img-fluid mx-3 pl-1" src="../assets/NAO/2-home/z (4).png" alt="">Col. Del Bosque, Ciudad de México</p> -->

          <p><img class="img-fluid mr-3" src="../assets/NAO/2-home/x (1).png" alt=""><a class="text-white" href="tel:(55)66513626">(55) 66 51 36 26</a></p>
          <p ><i class="fab h4 fa-whatsapp mr-3"></i><a class="text-white" href="https://api.whatsapp.com/send?phone=525513608624">+ 52 55 13 60 86 24</a></p>
          <p><img class="img-fluid mr-3" src="../assets/NAO/2-home/x (3).png" alt=""><a class="text-white" href="mailTo:amex@naotravelco.com">amex@naotravelco.com</a></p>          
        </div>
      </div>
    </div>

    </div>
    <div class="container-fluid" style="height:56px; background-color: gray">
      <div class="row">
        <div class="col-md-6 text-white mt-3"> Copyright © 2022 Naotravelco.</div>
        <div class="col-md-6 text-white mt-3 text-right"> Developed by Smartbis.</div>
      </div>


  </div>
</div>

</template>

<script>
export default {};
</script>

<style scoped>
ul {
  display: flex;
  justify-content: center;
  padding-left: 0px;
}
ul li {
  position: relative;
  display: block;
  color: rgb(233, 232, 232);
  font-size: 20px;
  height: 40px;
  width: 40px;
  background: #292525;
  line-height: 40px;
  border-radius: 50%;
  margin: 0 15px;
  cursor: pointer;
  transition: 0.5s;
}
a ul li:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
  /* background: #d35400; */
  border-radius: 50%;
  transform: scale(0.9);
  z-index: -1;
  transition: 0.5s;
}
ul li:nth-child(1):before {
  background: #4267b2;
}
ul li:nth-child(2):before {
  background: #1da1f2;
}
ul li:nth-child(3):before {
  background: #e1306c;
}
ul li:nth-child(4):before {
  background: #2867b2;
}
ul li:nth-child(5):before {
  background: #ff0000;
}
ul li:hover:before {
  filter: blur(3px);
  transform: scale(1.2);
  /* box-shadow: 0 0 15px #d35400; */
}
ul li:nth-child(1):hover:before {
  box-shadow: 0 0 15px #4267b2;
}
ul li:nth-child(2):hover:before {
  box-shadow: 0 0 15px #1da1f2;
}
ul li:nth-child(3):hover:before {
  box-shadow: 0 0 15px #e1306c;
}
ul li:nth-child(4):hover:before {
  box-shadow: 0 0 15px #2867b2;
}
ul li:nth-child(5):hover:before {
  box-shadow: 0 0 15px #ff0000;
}
ul li:nth-child(1):hover {
  color: #e23670;
  box-shadow: 0 0 15px #e23670;
  text-shadow: 0 0 15px #e23670;
}
ul li:nth-child(2):hover {
  color: #26a4f2;
  box-shadow: 0 0 15px #1da1f2;
  text-shadow: 0 0 15px #1da1f2;
}
ul li:nth-child(3):hover {
  color: #e23670;
  box-shadow: 0 0 15px #e1306c;
  text-shadow: 0 0 15px #e1306c;
}
ul li:nth-child(4):hover {
  color: #2a6cbb;
  box-shadow: 0 0 15px #2867b2;
  text-shadow: 0 0 15px #2867b2;
}
ul li:nth-child(5):hover {
  color: #ff1a1a;
  box-shadow: 0 0 15px #ff0000;
  text-shadow: 0 0 15px #ff0000;
}

/* ul li:hover{
  color: #ffa502;
  box-shadow: 0 0 15px #d35400;
  text-shadow: 0 0 15px #d35400;
} */
</style>
