<template>
<div>
  <div class="container-fluid ">
      <div class="row pb-md-0 pb-5">
        <div class="col-md p-0 movil" style="margin-top:0%;" >
           <img style="width:100%; height:100%;" src="../assets/NAO/2-home/r.png" alt="">
        </div>
        <div class="col-md-4 py-md-5 px-md-5 mt-5">
          <form  @submit.prevent="saveData()">
            <div class="px-3 pr-md-0 pr-4">
                <div class=" mb-2 ">
                    <div class="title " data-aos="fade-down">
                        <h1 class="text-left">Registro</h1>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-8 p-0 pl-3" data-aos="fade-right">
                        <input v-model="row.nombre" required type="text" class="form-control" id="exampleInputPassword1" placeholder="Nombre">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-8 p-0 pl-3" data-aos="fade-right" data-aos-duration="800">
                        <input v-model="row.email" required type="email" class="form-control" id="exampleInputPassword1" placeholder="Email">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-8 p-0 pl-3" data-aos="fade-right" data-aos-duration="500">
                        <input v-model="row.telefono" required type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57' class="form-control" id="exampleInputPassword1" placeholder="Teléfono">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-8 p-0 pl-3" data-aos="fade-right" data-aos-duration="1000">
                        <input v-model="row.agencia" required type="text" class="form-control" id="exampleInputPassword1" placeholder="Agencia">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-8 p-0 pl-3" data-aos="fade-right" data-aos-duration="1400">
                        <input v-model="row.clave" required type="password" class="form-control" id="exampleInputPassword1" placeholder="Contraseña">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-8 p-0 pl-3" data-aos="fade-right" data-aos-duration="1200">
                        <input required v-model="row.confirmaClave" type="password" class="form-control" id="exampleInputPassword1" placeholder="Confirmar contraseña">
                    </div>
                </div>
                <div data-aos="fade-up">
                  <b-form-checkbox required class="mt-4" id="checkbox-1" v-model="status" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                    <a  href="/avisodeprivacidad" target="_blank">Acepto el uso de mis datos de acuerdo al aviso de privacidad.</a>
                  </b-form-checkbox>
                </div>
                <button type="submit" class="btn btn-primary mt-4">Registrarme</button>
                
            </div>
          </form>
        </div>
          
          <div class="col-md p-0 full  bg-dark"  >
            <img style="width:100%;" class="animate__animated animate__fadeIn" src="../assets/NAO/2-home/r.png" alt="">
          </div>
      </div>
  </div>
</div>
</template>

<script>
import ProductVue from "../components/Product.vue";
import CardImg from "../components/Card-img.vue";
import ProductDetailVue from "../components/ProductDetail.vue";


export default {
  components: { ProductVue, ProductDetailVue, CardImg },
  data() {
    return {
      slide: 0,
      sliding: null,
     categories: [],
      productData: {},
      storeId: 0,
      productSearch: null,

      products: [],

      offerProducts: [],
 

      row:{},
      status: 0
      
    };
  },
  mounted() {
    
  },
  methods: {
    async saveData() {
      console.log(this.row)
      if(this.row.clave!= this.row.confirmaClave){
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "la contraseña no es igual.",
        });
        return
      }
    try {
        delete this.row.confirmaClave
        this.$isLoading(true)
        let req = await this.$store.dispatch("post", {
          path: 'clientes/new',
          data: this.row
        });
 
      delete this.row.clave
      req = await this.$store.dispatch("post", {
        path: 'enviarCorreo/registro',
        data: this.row
      });

        this.$isLoading(false)
        this.$router.push('/gracias_registro')
        // this.getData();
      } catch (error) {
        this.$isLoading(false)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });
        //alert(error);
      }
    },

    //////////////
  },
};
</script>
<style>
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  
  word-wrap: break-word !important; 
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;
  
  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{
  
  height:auto !important;
  width:264px !important;
  
}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

 
</style>