<template>
<div>
  <div class="container-fluid ">
      <div class="row">
        <div class="col-md-4 py-5 px-5 mt-5">
          <form @submit.prevent="recuperarPass">
            <div class="px-3">
                <div class=" mb-2 ">
                    <div class="title ">
                        <h2 class="text-left">Recuperación de contraseña</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-8 p-0 pl-3">
                        <input v-model="email" required type="email" class="form-control" id="exampleInputPassword1" placeholder="Email">
                    </div>
                </div>
                <button type="submit" class="btn btn-primary mt-4">Recuperar contraseña</button>
            </div>
          </form>
        </div>
          
          <div class="col p-0" >
           <img style="width:100%; height:100%;" src="../assets/NAO/2-home/recuperar.png" alt="">
          </div>
      </div>
  </div>
</div>
</template>

<script>
// import ProductVue from "../components/Product.vue";
// import CardImg from "../components/Card-img.vue";
// import ProductDetailVue from "../components/ProductDetail.vue";


export default {
  // components: { ProductVue, ProductDetailVue, CardImg },
  data() {
    return {
      slide: 0,
      sliding: null,
     categories: [],
      productData: {},
      storeId: 0,
      productSearch: null,

      products: [],
      offerProducts: [],
      email: ''

    };
  },
  mounted() {
    
  },
  methods: {
    async recuperarPass(){
      if (this.email.length== 0){
        alert('falta Email')
        return
      }
      let contacto = {
        "email":  this.email
      }
      let req = await this.$store.dispatch("post", {
        path: 'enviarCorreo/recuperacion',
      data: contacto
      });
      console.log('-->',req)
      if(req.message == 'Email no registrado en nuestra base de datos.'){
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: req.message,
        });
        return
      }

        this.$router.push('/enviada')
    }
  },
};
</script>
<style>
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  
  word-wrap: break-word !important; 
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;
  
  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{
  
  height:auto !important;
  width:264px !important;
  
}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

 
</style>