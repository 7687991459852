<template>
  <div :class="className" > 
    <b-card
        
        :img-src="product.img"
        img-alt="Image"
        img-top        
        tag="article"
        style="max-width: 26rem;shadow_custom; border-radius:15px !important;"
        class="mb-2 zoom flex "
        >
        <b-card-text>
        <h3>{{product.titulo}}</h3>
        <p v-html="product.descripcion" />
        <p>{{$moment(product.fecha).utc().format('DD-MM-YYYY')}}</p>
        </b-card-text>

        <b-button @click="$router.push(product.ruta)" variant="outline-primary">{{product.textoboton}}</b-button>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ["product", "theme"],
  data: () => ({
    className: "product",
    user: null
  }),
  mounted() {
    console.log('tarjeta item', this.product)
    this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
    if (this.theme) {
      this.className = this.theme;
    }
  },
  methods:{
    goto(rutaWeb){
      console.log('goto ruta: ', rutaWeb)
      this.$router.push(rutaWeb)
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: rgb(0, 0, 0);

.shadow_card {
  background: transparent;
  
  box-shadow: 0 .185rem .30rem rgba(0, 0, 0, 0.452)!important;
 
  
}

h4 {
  color: $primary;
}

.product-alt {
  background: transparent;
  border: 1px solid $primary;
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
  color: #fff;
}
.productimg{
  height:10rem; width:100%; display:block; margin:auto
}

@media screen and (max-width: 600px) {
 .productimg{
  height:10rem; width:50%; display:block; margin:auto
}
}
</style>