<template>
  <div >
      <div class="bg-gray">
        <div class="container py-5 px-4">
            <h1 class="py-4 text-center">{{tarjeta.detalle_titulo}}</h1>
            <div class="text-center">
                <!-- <img class="img-fluid" src="../assets/NAO/2-home/deal1.png" alt=""> -->
                <img class="img-fluid" style="border-radius:10px" :src="tarjeta.detalle_urlimagen" alt="">
            </div>
        </div>
      </div>
      <!-- <div class="" style="display: flex; justify-content: center; align-items: center;">
        <div style="max-width:45%; margin-top:-30px;" class=" border shadow_custom text-center bg-white">
          <div class="py-3 px-4 text-center">
              <h3><div class="title " data-aos="fade-down">
        <span><b> Otras recomendaciones</b></span>
      </div> regular: <span style="text-decoration:line-through;">$1,430USD</span> <span class="mx-4 h2 p-0" style="border-left-style: solid; border-width: 3px;"></span>Naodeal: $1,129USD</h3>
          </div>
      </div>
      </div>
      <div class="container-fluid">
          <div class="text-center p-5">
              <button class="btn btn-primary"><i class="fas fa-download mr-3"></i>Descargar editables</button>
          </div>
          <div class="mx-5">
              <h2 class="mt-5">Descripción de la promo:</h2>
              <p>
                    Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.
                    <br>Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi. Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.
                    <br>Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.  Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.
                    <br>Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.  
                    <br>Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.
                    <br>Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.  
              </p>
          </div>
      </div>
      <div class="container-fluid px-5 py-5">
        <h2 class="py-3 text-center">Galería</h2>
        <div class="row">
            <div class="col-md-6 pr-4 ">
                <img class="img-fluid" style="width:100%; height:100%;" src="../assets/NAO/2-home/deal2.png" alt="">
            </div>
            <div class="col-md-6 pl-4">
                <img class="img-fluid" style="width:100%; height:100%;" src="../assets/NAO/2-home/deal3.png" alt="">
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-6 pr-4 ">
                <img class="img-fluid" style="width:100%; height:100%;" src="../assets/NAO/2-home/deal4.png" alt="">
            </div>
            <div class="col-md-6 pl-4">
                <img class="img-fluid" style="width:100%; height:100%;" src="../assets/NAO/2-home/deal5.png" alt="">
            </div>
        </div>
      </div> -->
    <div class="text-center p-5 mb-3">
        <!-- <a :href="item.url" target="_blank" v-for="item in descargables" :key="item" class="btn btn-primary text-light mx-3 my-2"><i class="fas fa-download mr-3"></i>{{item.texto_boton}}</a> -->
        <!-- <a :href="item.url" target="_blank" v-for="item in descargables" :key="item" class="btn btn-primary text-light mx-3 my-2"><i class="fas fa-download mr-3"></i>{{item.texto_boton}}</a> -->
        <button v-if="tarjeta.detalle_boton1_ruta!=''" @click="goto(tarjeta.detalle_boton1_ruta)" class="btn btn-primary">{{tarjeta.detalle_boton1_texto ? tarjeta.detalle_boton1_texto : 'Descargar editable'}}</button><br>
        <button v-if="tarjeta.detalle_boton2_ruta!=''" @click="goto(tarjeta.detalle_boton2_ruta)" class="btn btn-primary mt-4">{{tarjeta.detalle_boton2_texto ? tarjeta.detalle_boton2_texto : 'Descargar editable'}}</button>


    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            categoria_id:0,
            id:0,
            tarjeta: {},
            descargables:[]
        }
    },
    async mounted(){
        this.id = this.$route.params.id
        await this.getData()
        await this.getDescargables()
    },
    methods:{
      goto(rutaWeb){
        console.log('goto ruta: ', rutaWeb)
        // console.log('goto ruta: ', this.product)
        // if(this.product.privado== 1 && !this.user.id){
        //   this.$router.push('/opps/product')
        // }
        // else{
          // if((this.product.categoria_id== 4 || this.product.categoria_id== 8) && this.product.documento_descargable_tar != null){
          //   window.location.href = this.product.documento_descargable_tar
          //   //window.open(this.product.documento_descargable_tar)
          // }
          // else{
            if(rutaWeb.includes('http')){
              console.log('web', rutaWeb)
              window.open(rutaWeb)
            }
            else{
              window.location.href =rutaWeb
              console.log('in', rutaWeb)
            }      
          // }
        // }
      },
            
    // goto(url){
    //   if(url.includes('http')){
    //     console.log('web', url)
    //     window.open(url)
    //   }
    //   else{
    //     window.location.href =url
    //     console.log('in', url)
    //   }      
    // },      
    async getDescargables() {
      try {
        let res = await this.$store.dispatch("get", { path:'descargables/getbyProducto/' + this.id});
        //console.log(res)
        if(res.length>0){
          this.descargables = res
        }
        else{
          this.descargables = []
        }
      } catch (error) {
        this.descargables = []
      }
    },      
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: 'tarjetas/getAll/' + this.id});
        if(res){
          console.log('tarjeta--Z',res)
          this.tarjeta = res[0]
        }
        else{
          this.tarjeta = {}
        }
      } catch (error) {
        this.tarjeta = {}
      }
    },
        
    }
}
</script>

<style lang="scss" scoped>

</style>