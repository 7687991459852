<template>
<div>
  <div class="container-fluid ">
      <div class="row">
        <div class="col-md p-0 movil" style="margin-top:0%;" >
           <img style="width:100%; height:100%;" src="../assets/NAO/2-home/Rectangle 5.png" alt="">
        </div>
            <div class="col-md-4 py-md-5 px-md-5 pr-md-0 pr-4 mt-md-5">
              <b-form @submit.prevent="login()">
                <div class="px-3 py-5">
                    <div class=" mb-2 ">
                        <div class="title " data-aos="fade-down">
                            <h1 class="text-left">Login</h1>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-md-8 p-0 pl-3" data-aos="fade-right">
                            <input v-model="row.email" required type="email" class="form-control" id="exampleInputPassword1" placeholder="Email">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-8 p-0 pl-3" data-aos="fade-right" data-aos-duration="500">
                            <input v-model="row.clave" required type="password" class="form-control" id="exampleInputPassword1" placeholder="Contraseña">
                        </div>
                    </div>
                    <button type="submit" data-aos="fade-right" data-aos-duration="800" class="btn btn-primary mt-4">Iniciar sesión</button>
                    <div class="mt-3">
                        <p data-aos="fade-up" data-aos-duration="800">¿Aún no tienes cuenta? <a @click="$router.push('/registro')" class="text-dark">Regístrate aquí</a> </p>
                        <p data-aos="fade-up" data-aos-duration="800"><a class="text-dark pointer" @click="$router.push('/recuperar_pass')">Olvide mi contraseña</a></p>
                    </div>
                </div>
              </b-form>
            </div>
          <div class="col-md p-0 full bg-dark" >
           <img style="width:100%;" class="animate__animated animate__fadeIn" src="../assets/NAO/2-home/Rectangle 5.png" alt="">
          </div>
      </div>
    <form action="https://www.reservasparquestematicos.com" method="POST" target="_self" style="height:1px;overflow:hidden;">
      <input type="hidden" name="agentID" value="" id="agent-id"><br>
      <input type="submit" value="Submit" id="send-form">
    </form>
  </div>
</div>
</template>


<script>
export default {
  data: () => ({
    registro: false,
    email: '',
    row: {
      email: '',//'desarrollo@smartbis.com.mx',
      clave: '', //'holahola'
      },
    origen: ''
  }),
  mounted(){
    this.origen = this.$route.params.origen
    // if(this.$store.state.user!=null){
    //   this.$router.push({name: 'Checkout'});
    // }
  },
  methods: {
    async recoverPassword(){
      if (this.email ==''){
        this.$swal({
          icon: 'error',
          title: 'Oopss!',
          text: 'Debes ingresar tu correo electrónico.'
        });
        return
      }
      const data ={
        mail: this.email
      }
      const recoverPass = await this.$store.dispatch("saveNew", {
        table: "recoverPassword",
        data
      });
      this.$bvModal.hide('modal-recoverPassword');
      this.email = ''
      this.$swal({
        icon: 'success',
        title: 'Muy bien!',
        text: 'En breve te enviaremos tu nueva contraseña!'
      });
    },
    async login() {
      // let user={
      //   status: 1,
      //   address:'Calle la for Nº25',
      //   phone: '505-55698785',
      //   nit: '12321-2121123',
      //   customerName:'José Pérez',
      //   rol: 1
      // }
      try {
        this.$isLoading(true)
        let req = await this.$store.dispatch("login", { data: this.row});
        this.$isLoading(false)

        if(req){
          this.$store.commit('setUser', req.data)

          // custom process
          if(localStorage.getItem("sourceparks") && localStorage.getItem("sourceparks") == "yes"){
            let userdata = JSON.parse(localStorage.getItem('user')) || null;
            if (userdata){ // same protection stablished here too
              if (userdata.estado){ // also theck if the user is active (not null)
                document.getElementById("agent-id").value = userdata.email;
                document.getElementById("send-form").click();
              }else{
                this.$swal({
                  icon: "error",
                  title: "Oopss!",
                  text: "Error, usuario pendiente de validación.",
                });
              }
            }
          }
          // end of process

          if(this.origen == 'naodeals'){
            this.$router.push("/naoDeals");
            return
          }
          if(this.origen == 'naotools'){
            this.$router.push("/admin/NAO_Tools");
            return
          }
          if(req.data.rol == 1){
            this.$router.push("/");
          }
          if(req.data.rol == 99){
            if(req.data.estado== 1){
              this.$router.push("/");
            }
            else{
              this.$swal({
                icon: "error",
                title: "Oopss!",
                text: "Error, usuario pendiente de validación.",
              });
            }
          }




        }
        else{
          }
      } catch (error) {
        this.$isLoading(false)
        this.$swal({
            icon: 'error',
            title: 'Oopss!',
            text: 'Error verificar credenciales.' // + user,
          });
        console.log(error);
      }
    },
  },
};
</script>


<style>

.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;

  scroll-snap-type: mandatory;
  scroll-behavior: smooth;

  word-wrap: break-word !important;
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;

  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{

  height:auto !important;
  width:264px !important;

}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}


</style>
