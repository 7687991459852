<template>
<div>
  <div class="container-fluid  pb-5">
      <div class="row pb-4">
          <div class="col col-lg-8 offset-lg-2 px-5 mt-5">
            <h1>Aviso de privacidad</h1>

            <p class="text-justify mt-5">Los datos personales que proporciones a través del Newsletter, sitio web, teléfono o sitio
              web de Nao Travel Collection serán utilizados exclusivamente por Nao Cruises S.A.P.I. de
              C.V. (en lo sucesivo “Nao Travel Collection”), con domicilio en Boulevard Manuel Ávila
              Camacho No. 184-PB. “Nao Travel Collection” recabará tu correo electrónico con la
              finalidad de llevar a cabo tu registro a nuestro Newsletter, así como enviar información
              adicional acerca de los servicios, capacitaciones y promociones de la compañía. Así
              mismo, podremos utilizar tus datos para atender y dar seguimiento a cualquier solicitud,
              pregunta, comentario sobre nuestro Newsletter y enviar notificaciones sobre posibles
              cambios a este Aviso de Privacidad. En cualquier momento podrás limitar el uso de tus
              datos personales para las finalidades anteriormente expuestas, por lo que si ya no deseas
              recibir comunicaciones nuestras, envíanos un correo electrónico a
              newsletter@naotravelco.com dirigido a nuestra área de marketing indicándonos dicha
              situación a efecto, de incluir tu información en un listado de exclusión. Únicamente,
              podremos transferir tus datos personales si una Ley o un proceso judicial, mediante
              autoridad competente, así nos lo exige. En caso de no estar de acuerdo con las finalidades
              planteadas en nuestro Aviso de Privacidad, podrás ejercer los derechos de acceso,
              rectificación, cancelación u oposición de tus datos personales (Derechos ARCO y dirigirlos
              al área de marketing de “Nao Travel Collection” a través del correo electrónico
              newsletter@naotravelco.com. La falta de oposición al presente Aviso de Privacidad o la
              simple continuación en la solicitud de servicios una vez publicado y puesto a su
              disposición, implica una aceptación expresa de los términos del mismo. “Nao Travel
              Collection” velará por el cumplimiento de los principios de protección de datos personales
              y adoptará las medidas de seguridad establecidas en la Ley Federal de Protección de Datos
              Personales en Posesión de los Particulares y las demás normatividades aplicables. El
              presente Aviso de Privacidad podrá sufrir modificaciones, cambios o actualizaciones
              derivadas de nuevos requerimientos legales o con nuevas políticas internas, por lo que
              “Nao Travel Collection” comunicará dichas actualizaciones en este enlace.
            </p>
            <p>Fecha de última actualización 01/02/2022</p>
          </div>
      </div>
  </div>
    
  <!-- categorias -->
  
  <!-- products -->

  <!-- fin -->
</div>
</template>

<script>

export default {
  components: {  },
  data() {
    return {

    };
  },
  async mounted() {
    this.$isLoading(true)
  },
  methods: {
    scrollFix: function(hash) {
      setTimeout(() => {
        const el = document.querySelector('#'+hash)
        el && el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 2000);
      // setTimeout(() => $('html, body').animate({
        
      // scrollTop: $('#'+hash).offset().top
      // }, 1000), 1)
    },        
    goto(url){
      if(url.includes('http')){
        console.log('web', url)
        window.open(url)
      }
      else{
        window.location.href =url
        console.log('in', url)
      }      
      // console.log('goto ruta: ', rutaWeb)
      // this.$router.push(rutaWeb)
    },    

    
  },
};
</script>
<style scoped>

.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  
  word-wrap: break-word !important; 
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;
  
  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{
  
  height:auto !important;
  width:264px !important;
  
}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

 
</style>