<template>
<div>
  <div class="container-fluid bg-gray pb-5">
      <div class="row pb-4">
          <div class="col-md-4 py-5 pl-5 mt-5 full">
            <div class="ml-3">
              <h1 class=" text-left">Nuestras mejores promos</h1>
              <p class="py-4">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
              <button class="btn btn-primary">Ver todo <i class="px-2 fas fa-chevron-down"></i> </button>
            </div>
          </div>
          
          <div class="col-md-8 p-md-5 mt-md-0 mt-4" >
            <b-carousel
            id="carousel-1"
            v-model="slide"
            indicators
            background="#ababab"
            class="pl-md-5"
            
            style="text-shadow: 1px 1px 2px #333; background:transparent; "
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"      
          >
            <!-- <b-carousel-slide :img-src="require('@/assets/slide1.jpg')" />
            <b-carousel-slide :img-src="require('@/assets/slide2.jpg')"/>
            <b-carousel-slide :img-src="require('@/assets/slide3.jpg')"/>
            <b-carousel-slide :img-src="require('@/assets/slide4.jpg')"/>
            <b-carousel-slide :img-src="require('@/assets/slide5.jpg')"/> -->
            <b-carousel-slide class="p-0" style=" background: transparent !important;">
              <template class="p-0" #img style=" background: transparent !important;">
                <div class="row p-0" style=" background: transparent !important;">
                  <card-img :class="'col-'+ item.columnas" v-for="item in card_img2" 
                  :key="item.id"
                  :product="item" />

                </div>
              </template>
            </b-carousel-slide>
             <b-carousel-slide class="p-0" style=" background: transparent !important;">
              <template class="p-0" #img style=" background: transparent !important;">
                <div class="row p-0" style=" background: transparent !important;">
                  <card-img :class="'col-'+ item.columnas" v-for="item in card_img2" 
                  :key="item.id"
                  :product="item" />

                </div>
              </template>
            </b-carousel-slide>
              
              
                
            </b-carousel>
              <!-- <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                indicators
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
                >                       
                    <b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image">
                        <div class="row px-3 " style="whidth:1024px !imporatant; height:480px !important;" >
                            <card-img :class="'col-'+ item.columnas" v-for="item in card_img2" 
                            :key="item.id"
                            :product="item" />

                        </div>
                    </b-carousel-slide>
                    
                </b-carousel> -->
          </div>
          <div class="col-md-4 py-5 pl-md-5 mt-5 movil">
            <div class="ml-md-3">
              <h1 class=" text-left">Nuestras mejores promos</h1>
              <p class="py-4">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
              <button class="btn btn-primary">Ver todo <i class="px-2 fas fa-chevron-down"></i> </button>
            </div>
          </div>
      </div>
  </div>

    
    
  <!-- categorias -->
  


  <!-- products -->
  <div class="container-fluid my-5 px-md-5">
    <div class=" mt-2 mb-2 mx-md-4">
      <div class="title ">
        Categoría 1
      </div>
      <p class="">	Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
    </div>
    
    <div class="inner only-this-horizon-scrollbar" style="">
      <div class="scroll-container diff-size " >
        <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
        <div class="scroll-section "  v-for="item in card_promos" :key="item">
            <product-vue @click="showProductDetail(item)" :product="item" class="zoom" />
        </div>
      </div>
    </div>
    <p class="h6 text-right mx-4 pointer">Ver todo <i class="fas fa-long-arrow-alt-right"></i></p>
  </div>
 <div class="container-fluid my-5 px-md-5">
    <div class=" mt-2 mb-2 mx-md-4">
      <div class="title ">
        Categoría 2
      </div>
      <p class="">	Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
    </div>
    
    <div class="inner only-this-horizon-scrollbar" style="">
      <div class="scroll-container diff-size " >
        <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
        <div class="scroll-section "  v-for="item in card_promos" :key="item">
            <product-vue @click="showProductDetail(item)" :product="item" class="zoom" />
        </div>
      </div>
    </div>
    <p class="h6 text-right mx-4 pointer">Ver todo <i class="fas fa-long-arrow-alt-right"></i></p>
  </div>
  
  
  <!-- offers -->
  <div class="container-fluid px-md-5 pb-5">
    <div class=" mt-2 mb-2 mx-md-4">
      <div class="title ">
        Otras recomendaciones
      </div>
      <p class="">	Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
    </div>

    <div class="row px-md-3 card_full">
      <card-img :class="' col-'+ item.columnas" v-for="item in card_img" 
      :key="item.id"
      :product="item" />
    </div>

    <div class="row px-md-3 card_movil">
      <card-img :class="'px-0 col-12'" v-for="item in card_img" 
      :key="item.id"
      :product="item" />
    </div>
  </div>
   
  <div class="container-fluid bg-white my-5">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 px-md-5">
        <div class="mt-5 mb-2 mx-md-4">
          <div class="title ">
            ¿Tienes alguna duda?
          </div>
          <p class="mt-3">¡Será un placer escucharte y planear juntos!</p>
        </div>
        <div class="mx-md-2">
<p data-aos="fade-right" data-aos-duration="950"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (1).png" alt=""><a class="text-black" href="tel:(55)66513626">(55) 66 51 36 26</a></p>
          <p data-aos="fade-right" data-aos-duration="1000"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (2).png" alt=""><a target="_blank" class="text-black" href="https://api.whatsapp.com/send?phone=+525513608649">+ 52 55 13 60 86 49</a></p>
          <p data-aos="fade-right" data-aos-duration="1050"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (3).png" alt=""><a class="text-black text-decoration:none" href="mailTo:ventas@naotravelco.com">ventas@naotravelco.com</a></p>
          <p data-aos="fade-right" data-aos-duration="1050"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (3).png" alt=""><a class="text-black text-decoration:none" href="mailTo:reservaciones@naotravelco.com">reservaciones@naotravelco.com</a></p>
          <p data-aos="fade-right" data-aos-duration="1100"><img class="img-fluid mx-3 pl-1" src="../assets/NAO/2-home/z (4).png" alt="">Col. Del Bosque, Ciudad de México</p>
        </div>
      </div>

      <div class="col-sm-12 col-md-8 col-lg-8 px-md-5">
        <div class="mt-5">
          <form class="px-md-3">
            <div class="form-group my-3 card_full">
              <div class="row">
                <div class="col-md-4 p-0 pl-3 mr-0">
                  <input type="text" class="form-control mb-3" id="exampleInputPassword1" placeholder="Nombre">
                </div>
                <div class="col-md-4 p-0 pl-3 mr-0">
                  <input type="email" class="form-control mb-3" id="exampleInputPassword1" placeholder="Email">
                </div>
                <div class="col-md-4 p-0 pl-3 mr-0">
                  <input type="text" class="form-control mb-3" id="exampleInputPassword1" placeholder="Teléfono">
                </div>
                <div class="col-12 p-0 pl-3 mr-0">
                  <div class="form-group">
                    <textarea class="form-control" cols="100" style="height:12rem;" placeholder="Tu mensaje" id="exampleFormControlTextarea1" rows="3"></textarea>
                  </div>
                </div>
              </div>
              
            </div>

            <div class="form-group row my-3 card_movil">
              <div class="col-sm-12 col-md-4 p-0 mr-0">
                <input type="text" class="form-control mb-3" id="inputname" placeholder="Nombre">
              </div>
              <div class="col-sm-12 col-md-4 p-0 mr-0">
                <input type="email" class="form-control mb-3" id="inputemail" placeholder="Email">
              </div>
              <div class="col-sm-12 col-md-4 p-0 mr-0">
                <input type="text"  class="form-control mb-3" style="margin-left:0px !important" id="inputphone" placeholder="Teléfono">
              </div>
                <div class="col-12 p-0 pl-md-3 mr-0">
                  <div class="form-group">
                    <textarea class="form-control" style="height:12rem; " placeholder="Tu mensaje" id="exampleFormControlTextarea1" rows="3"></textarea>
                  </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary">Enviar</button>
          </form>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import ProductVue from "../components/Product.vue";
import CardImg from "../components/Card-img.vue";
import ProductDetailVue from "../components/ProductDetail.vue";
import chooseStore from "../components/ChooseStore";

export default {
  components: { ProductVue, ProductDetailVue, chooseStore, CardImg },
  data() {
    return {
      slide: 0,
      sliding: null,
     categories: [],
      productData: {},
      storeId: 0,
      productSearch: null,

      products: [],

      offerProducts: [],
      card_img:[
        {id: 1, 
        titulo: "Cruceros", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 12, 
        ruta: '/cruceros',
        img: '../img/Banner2.png',
        posicionpanel: 'arriba', 
        columnaspanel:6,
        width: '480',
        height: '370',
        alturapanel: 170,
         
        panelhorizontal: 0,
        izquierda: 6,
        },
        {id:2, 
        ruta: '/circuitos',
        titulo: "Circuitos", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 7 , 
        img: '../img/Banner.png',
        posicionpanel: 370, 
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 0,
        alturapanel: 170,
        
        },
        
        {id: 3, 
        ruta: '/hotel',
        titulo: "Hoteles", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 5, 
        img: '../img/Banner_1.png',
        posicionpanel: 'arriba', 
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 115,
        panelhorizontal: 1,
        col_text: 7,
        col_button: 4,
        
        },
        {id: 3, 
        ruta: '/luxury',
        titulo: "Luxury Collection", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 5, 
        img: '../img/Banner_2.png',
        posicionpanel: 'arriba', 
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 115,
        posicionpanel: 370, 
        col_text: 7,
        col_button: 4,
        panelhorizontal: 1,
        },
        {id:4, 
        ruta: '/',
        titulo: "Promociones y recursos para agentes de viaje ", 
        descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.", 
        textoboton: "Ver mas", 
        columnas: 7 , 
        img: '../img/Banner_3.png',
        posicionpanel: 370, 
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 2,
        alturapanel: 200,
        
        },
        
        
      ],
      card_img2:[
        {id:1, 
        titulo: " Super Safari ", 
        descripcion:"Desde 1,400 USD", 
        textoboton: "Ver mas", 
        columnas: 12 , 
        img: '../img/deels.png',
        posicionpanel: 370, 
        columnaspanel:6 ,
        panelhorizontal:1,
        width: '930',
        height: '370',
        izquierda: 0,
        alturapanel: 110,
        col_text: 5,
        col_button: 7,
        
        },
        
        
        
        
        
      ],
      card_promos:[
        {id:1, 
        titulo: "Crucero por el mar mediterraneo", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        img: '../img/card_.png',
        textoboton: 'Descargar'
        },
        {id:2, 
        titulo: "Crucero por el mar mediterraneo", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        img: '../img/card_.png',
        textoboton: 'Descargar'
        },
        {id:3, 
        titulo: "Crucero por el mar mediterraneo", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        img: '../img/card_.png',
        textoboton: 'Descargar'
        },
        {id:4, 
        titulo: "Crucero por el mar mediterraneo", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        img: '../img/card_.png',
        textoboton: 'Descargar'
        },
        {id:5, 
        titulo: "Crucero por el mar mediterraneo", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        img: '../img/card_.png',
        textoboton: 'Descargar'
        },
        {id:6, 
        titulo: "Crucero por el mar mediterraneo", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        img: '../img/card_.png',
        textoboton: 'Descargar'
        },
        {id:7, 
        titulo: "Circuitos", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        img: '../img/card_.png',
        textoboton: 'Descargar'
        },
        {id:8, 
        titulo: "Crucero por el mar mediterraneo", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        img: '../img/card_.png',
        textoboton: 'Descargar'
        },
        {id:9, 
        titulo: "Crucero por el mar mediterraneo", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        img: '../img/card_.png',
        textoboton: 'Descargar'
        },
        {id:10, 
        titulo: "Crucero por el mar mediterraneo", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        img: '../img/card_.png',
        textoboton: 'Descargar'
        },
               
        
      ],
    };
  },
  mounted() {
    
  },
  methods: {
    goSearch() {
      localStorage.setItem("search", this.productSearch);
      this.$router.push("store/0");
    },
    gotoStore(category) {
      this.$router.push("store/" + category);
    },
    async getProducts() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.products = await this.$store.dispatch(
          "getAll",
          "newProductsByStore/" + this.storeId
        );
      } catch (error) {
        alert(error);
      }
    },
    async productsOffer() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.offerProducts = await this.$store.dispatch(
          "getAll",
          "productsOffer/" + this.storeId
        );
        if(this.offerProducts){
          this.offerProducts = this.offerProducts.filter(x => x.variations.length>0);
        }
      } catch (error) {
        alert(error);
      }
    },
    showProductDetail(item) {
      item["quantity"] = 1;
      this.productData = item;
      this.$bvModal.show("modal-productDetail");
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    showModal() {
      this.$bvModal.show("modal-chooseStore");
    },
    closeModal() {
      this.$bvModal.hide("modal-chooseStore");
      this.$bvModal.hide("modal-productDetail");
    },
  },
};
</script>
<style>

.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  
  word-wrap: break-word !important; 
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;
  
  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{
  
  height:auto !important;
  width:264px !important;
  
}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

 
</style>